<template>
	<div class="mainTem" :style="{'height':$store.state.frameConHeight1+'px','overflow':'auto'}">




		<el-form ref="form" :disabled="isOnlyView" :rules="formRules" :model="form" inline label-width="100px"
			style="width: 100%;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">

			<!-- <el-form-item :label="$t('i18nn_c944a6686d996ab3')" prop="" required>
				<el-select filterable clearable size="" v-model="form.whNo" :placeholder="$t('FormMsg.Please_select')">
					<el-option v-for="item in selectOption.whNo" :key="item.code" :label="$Utils.i18nCodeText(item)"
						:value="item.code"></el-option>
				</el-select>
			</el-form-item> -->

			<el-form-item :label="$t('i18nn_c944a6686d996ab3')" prop="" required>
				<whNoSelect ref="whNoSelect" @changeData="changWhNo" size="''"></whNoSelect>
			</el-form-item>

			<el-form-item :label="$t('i18nn_3d3bc466f13154d4')" prop="" required>
				<el-input type="" placeholder="" v-model.trim="form.packingNo">
				</el-input>
			</el-form-item>

			<el-form-item :label="$t('i18nn_611f87335433e0c3')" prop="" required>
				<!-- <el-input type=""  placeholder="" v-model="form.packType">
				</el-input> -->

				<el-select filterable clearable v-model="form.packType" :placeholder="$t('FormMsg.Please_select')"
					style="width: 150px;">
					<el-option v-for="item in selectOption.wh_transfer_arrive_way" :key="item.code"
						:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item :label="$t('i18nn_e1e2b21c7b4b951f')" prop="" required>
				<el-select filterable clearable v-model="form.trType" :placeholder="$t('FormMsg.Please_select')"
					style="width: 150px;">
					<el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code" :label="$Utils.i18nCodeText(item)"
						:value="item.code"></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item :label="$t('i18nn_9be246e60da2a0a2')" prop="" required>
				<!-- <el-input type=""  placeholder="" v-model="form.planArrivalDate"> -->
				<el-date-picker v-model="form.planArrivalDate" type="date" value-format="yyyy-MM-dd"
					:placeholder="$t('FormMsg.Select_date_time')"></el-date-picker>
				</el-input>
			</el-form-item>
			<el-form-item :label="$t('i18nn_c8773d8d74202801')" prop="" required>
				<el-input-number size="" v-model="form.pallteCount" controls-position="right">
				</el-input-number>
			</el-form-item>
			<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="">
				<el-input type="textarea" :rows="3"
					placeholder="可明确操作指令：比如：1、贴标要求：贴标数量、贴标方式（SKU标/箱标/托盘标）等；2、打托要求；3、是否需要拍照；体积测量、重量测量等其他需要仓库操作的事项；"
					v-model="form.remark" :maxlength="2000" show-word-limit style="width: 500px;">
				</el-input>
			</el-form-item>


			<div>
				<el-button type="primary" icon="el-icon-plus"
					@click="AddBlockData()">{{$t('i18nn_c45d595e849aec6a')}}</el-button>
			</div>
			<el-card class="hover-highlight-red" :shadow="'never'" v-for="(item,index) in form.plandtoList" :key="index"
				style="margin:10px;">
				<div slot="header">
					<div style="display: flex;justify-content: space-between;">
						<strong><span>{{$t('i18nn_59550e3d8c8088f9')}}</span>{{index+1}}</strong>
						<div>
							<el-button type="warning" icon="el-icon-minus"
								@click="DelBlockData($event,index,item)">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
						</div>
					</div>
				</div>

				<div>

					<!-- <el-form-item :label="$t('i18nn_c944a6686d996ab3')" prop="" required>
						<el-select filterable clearable size="" v-model="item.whNo" :placeholder="$t('FormMsg.Please_select')">
							<el-option v-for="item in selectOption.whNo" :key="item.code" :label="$Utils.i18nCodeText(item)"
								:value="item.code"></el-option>
						</el-select>
					</el-form-item> -->

					<!-- <el-form-item :label="$t('i18nn_a29209bd396e4bdd')" prop="" required>
						<el-select filterable clearable size="" v-model="item.trWay" :placeholder="$t('FormMsg.Please_select')">
							<el-option v-for="item in selectOption.biz_wh_tr_plan_way" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</el-form-item> -->
					
					<el-form-item :label="$t('i18nn_e1e2b21c7b4b951f')" prop="" required>
						<el-select filterable clearable v-model="item.trWay" :placeholder="$t('FormMsg.Please_select')"
							style="width: 150px;" @change="trWayChange($event,index)">
							<el-option v-for="item2 in selectOption.wh_car_tms_type" :key="item2.code" :label="$Utils.i18nCodeText(item2)"
								:value="item2.code"></el-option>
						</el-select>
					</el-form-item>

					
					
					<!-- 只有卡派需要选择地址，自提和快递不需要选择地址 -->
					<el-form-item :label="$t('i18nn_0bbce74881c893df')" prop="" required v-if="'2'==item.trWay">
						<!-- <el-input type="text" clearable v-model="item.addrType"
							:placeholder="$t('i18nn_c6bf739e696c2bfc')"></el-input> -->
						<el-select filterable clearable size="" v-model="item.addrType" :placeholder="$t('FormMsg.Please_select')"
							@change="clearAddrCode($event,index)">
							<el-option v-for="item in selectOption.biz_wh_tr_plan_addr_type" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</el-form-item>
					
					<!-- 只有卡派需要选择地址，自提和快递不需要选择地址 -->
					<el-form-item :label="$t('i18nn_5cd591b578350972')" prop="" required v-if="'2'==item.trWay">
						<span v-if="'0'==item.addrType">
							<el-input type="text" disabled v-model="item.trAddrCode" :placeholder="$t('FormMsg.Please_select')"
								style="width: 150px;"></el-input>
							<el-button type="primary" size="small" @click="openSelAddr(index)"
								icon="el-icon-magic-stick">{{$t('i18nn_7fb6ada66a1fccca')}}</el-button>
							<span>
								{{item.trAddrInfo}}
							</span>

						</span>
						<span v-else>
							<el-input type="text" clearable v-model="item.trAddrCode"
								:placeholder="$t('i18nn_5a9aefbc03c778f7')"></el-input>
						</span>
					</el-form-item>
					
					<!-- 自提：自提单号
					快递-渠道商：Fedex，UPS -->
					<el-form-item :label="$t('i18nn_7f0e36c0c32152b9')" prop="" required v-if="'1'==item.trWay">
						<el-input type="text" clearable v-model="item.trAddrCode"
							:placeholder="''"></el-input>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_316e16926411681a')" prop="" required v-if="'3'==item.trWay">
						<el-input type="text" clearable v-model="item.trAddrCode"
							:placeholder="'Fedex,UPS...'"></el-input>
					</el-form-item>
					
					
					<el-form-item :label="$t('i18nn_fa02547543cff0f7')" prop="" required>
						<!-- <el-input type="text" clearable v-model="item.trWay" :placeholder="$t('i18nn_c6bf739e696c2bfc')"></el-input> -->
						<el-select filterable clearable size="" v-model="item.isChangeLabel"
							:placeholder="$t('FormMsg.Please_select')" style="width: 150px;">
							<el-option v-for="item in selectOption.yes_no" :key="item.code" :label="$Utils.i18nCodeText(item)"
								:value="item.code"></el-option>
						</el-select>
					</el-form-item>
					
					
					<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="">
						<el-input type="textarea" :rows="2" :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="item.remark" :maxlength="1000" show-word-limit  style="width: 500px;">
						</el-input>
					</el-form-item>
					
					<!-- <div>
						<el-form-item :label="$t('i18nn_886ea69af099215d')" prop="">
							<el-input-number size="" v-model="item.pallteCount" controls-position="right">
							</el-input-number>
						</el-form-item>
						<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="">
							<el-input type="textarea" :rows="3" placeholder="" v-model="item.remark" style="width: 500px;">
							</el-input>
						</el-form-item>
					</div> -->



					<!-- <el-card :shadow="'never'"> -->
					<!-- <div slot="header">
						<div>{{$t('i18nn_e6bdd31025bb4f9f')}}</div>
					</div> -->

					<!-- <div> -->

					<!-- "ctnMark":"外箱标识",
							                "quantity":"数量",
							                "trRecordId":"入库明细ID",
							                "fbaNo":"FBA编号",
					                "fbaPre":"FBA PRE#" -->
					<div>
						<el-button type="success" size="small" icon="el-icon-plus"
							@click="addDet(index)">{{$t('i18nn_a1c919aef2dec6d4')}}</el-button>
						
						<el-badge v-if="item.planList" :value="item.planList.length">
						</el-badge>
					</div>


					<el-table border :data="item.planList" size="small" :max-height="$store.state.frameConHeightWh4"
						style="width: 100%;">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('Storage.tableColumn.no')"></el-table-column>

						<!-- <el-table-column prop="inWhCode" :label="$t('i18nn_35ec768075eb8f4e')">
						</el-table-column> -->

						<el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
							<template slot-scope="scope">
								<div>
									<!-- {{scope.row.ctnMark}} -->
									<el-input size="small" v-model="scope.row.ctnMark" :placeholder="$t('i18nn_73fc38e67381ae73')">
									</el-input>
								</div>
							</template>
						</el-table-column>


						<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')" width="">
							<template slot-scope="scope">
								<div>
									<el-input-number size="small" v-model="scope.row.quantity" controls-position="right">
									</el-input-number>
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="" width="">
							<template slot="header">
								<span>总重量(lb)</span>
							</template>

							<template slot-scope="scope">
								<div>
									<el-input-number size="small" v-model="scope.row.totalWeight" controls-position="right">
									</el-input-number>
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="" width="">
							<template slot="header">
								<span>总体积(m³)</span>
							</template>

							<template slot-scope="scope">
								<div>
									<el-input-number size="small" v-model="scope.row.totalVol" controls-position="right">
									</el-input-number>
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="fbaNo" :label="'FBA编号'" width="">
							<template slot-scope="scope">
								<div>
									<el-input size="small" v-model="scope.row.fbaNo" :placeholder="'FBA编号'">
									</el-input>
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="fbaPre" :label="'PO'" width="">
							<template slot-scope="scope">
								<div>
									<el-input size="small" v-model="scope.row.fbaPre" :placeholder="'PO'">
									</el-input>
								</div>
							</template>
						</el-table-column>

						<el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right" align="left"
							v-if="!isOnlyView">
							<template slot-scope="scope">
								<div>
									<el-button @click="delDet($event, index, scope.$index)" type="danger" size="small"
										icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<!-- </div> -->
					<!-- </el-card> -->


					<!-- <el-card :shadow="'never'">
						<div slot="header">
							<div>
								<strong>{{$t('i18nn_f5d43732e3f6cf4d')}}</strong>
							</div>
						</div>

						<div>
							<el-button type="primary" icon="el-icon-paperclip" size="mini"
								@click="openAddFile(index)">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>

							<ul>
								<li v-for="(item2,index2) in item.attachments" :key="index2">
									<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
										:src="item2.url" :preview-src-list="[item2.url]">
										<div slot="error" class="image-slot">
											<i class="el-icon-document"></i>
										</div>
									</el-image>
									<a :href="item2.url" :title="item2.url" target="_blank">{{ item2.fileName }}</a>

									<el-button @click="delFile($event,index, index2)" type="text" size="mini" icon="el-icon-delete"
										style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
								</li>
							</ul>
						</div>
					</el-card> -->

				</div>
			</el-card>







		</el-form>

		<div class="submit-footer" style="">
			<!-- <div v-if="0==stepActive">
				<el-button type="primary" @click="nextStep">{{$t('i18nn_4639f3a6e07c00b3')}}</el-button>
			</div>
			<div v-else-if="1==stepActive">
				<el-button @click="preStep">{{$t('i18nn_70e058bc23d52c1e')}}</el-button>
				<el-button type="primary" @click="nextStep">{{$t('i18nn_4639f3a6e07c00b3')}}</el-button>
			</div>
			<div v-else-if="2==stepActive">
				<el-button @click="preStep">{{$t('i18nn_70e058bc23d52c1e')}}</el-button>
				<el-button type="warning" @click="submitFormAction()" style="width: 200px;"
					v-if="!isOnlyView">{{$t('i18nn_e186babc2e9cadf4')}}
				</el-button>
			</div> -->
			<!-- <el-button type="warning" @click="submitFormAction()" style="" v-if="!isOnlyView">{{$t('i18nn_e186babc2e9cadf4')}}
			</el-button> -->
			<el-button type="warning" @click="submitFormAction()"
				icon="el-icon-tickets">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
		</div>



		<!-- 要运送的货物 -->
		<!-- <div style="" v-if="!isOnlyView">
			<el-drawer :wrapperClosable="false" :title="$t('i18nn_2ad108ab2c560530')" :append-to-body="true" :size="'60%'"
				:visible.sync="drawer" :direction="'rtl'">
				<div>
					<TransportPlanOutWhSelPage ref="TransportInWhDetPageList" :openTime="TransportInWhDetOpenTime"
						:whNo="TransportInWhDetWhNo">
					</TransportPlanOutWhSelPage>
					<div style="margin: 10px;">
						<el-button icon="el-icon-check" type="warning" @click="addRec()">
							{{$t('i18nn_760f75dc397a9cb8')}}</el-button>
					</div>
				</div>

			</el-drawer>

		</div> -->

		<!--  附件上传 -->
		<!-- <whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUpload> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto"
					height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!--选择sku-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0">
			<div style=""><WSkuInfo :isSel="true" :status="'1'" @selectRow="selWhGoodsData"></WSkuInfo></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSelVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!-- 地址 -->
		<TransportAddressListDialog :openTime="TransportAddressOpenTime" @selSuccess="addrSelSuccess">
		</TransportAddressListDialog>
	</div>
</template>
<script>
	import {
		deepClone
	} from '@/utils/common.js';
	import {
		getDicData
	} from '@/axios/common.js';
	// import TransshipmentImport from '@/components/StorageCenter/workOrder/workOrderTem/TransshipmentImport.vue';
	// import WSkuInfo from '@/components/StorageCenter/WSkuProduct/WSkuInfo.vue';
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	// import transferAddressSel from '@/components/StorageCenter/components/transferAddressSel.vue';
	// import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	// import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';
	// import whFileUploadInner from '@/components/StorageCenter/components/whFileUploadInner.vue';
	// import HotTableTransportInWh from '@/components/StorageCenter/Transport/HotTableTransportInWh.vue';
	// import TransportInWhDetListScroll from '@/components/StorageCenter/Transport/TransportInWhDetListScroll.vue';
	// import TransportSkuInventoriesListScroll from '@/components/StorageCenter/Transport/TransportSkuInventoriesListScroll.vue';

	import TransportAddressListDialog from '@/components/StorageCenter/Transport/TransportAddressListDialog.vue';

	// import TransportPlanOutWhSelPage from '@/components/StorageCenter/Transport/TransportPlanOutWhSelPage.vue';

	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		// 	// mobile:"",
		// 	editId: {
		// 		// default: function() {
		// 		//   return false;
		// 		// },
		// 		// type: Boolean
		// 	},
		// 	openTime: {
		// 		// default: function() {
		// 		//   return '';
		// 		// },
		// 		// type: String
		// 	}
		// },
		components: {
			// WSkuInfo,
			// HyElWhUpLoad,
			// HyQuillEditor,
			// TransshipmentImport
			TransportAddressListDialog,
			// TransportPlanOutWhSelPage,
			// TransportInWhDetListScroll,
			// TransportSkuInventoriesListScroll,
			whNoSelect,
			// transferAddressSel,
			// whFileUpload,
			// dialogFileUpload
			// HotTableTransportInWh
			// whFileUploadInner
		},
		data() {
			return {
				// stepActive: 0,
				// serKeyword: "",
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				// dialogFormMsg: '',
				editId: '',
				//导入excel
				// dialogUploadVisible: false,
				loadingExcel: false,
				fileExcel: '',
				// excelData: [],
				excelFileName: '',

				// fileRelationId: '',
				// FileUploadOpenTime: '',

				TransportOpenIndex: 0,

				// TransportInWhDetOpenTime: "",
				// TransportInWhDetWhNo: "",

				TransportAddressOpenTime: "",

				drawer: false,

				// FileUploadOpenTime: '',

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				//打开SKU选择框
				// dialogSelVisible: false,
				// openRowData: {},
				// openRowIndex: {},

				loading: false,

				loading_det: false,

				// isSaveAddress: false,
				// addressName: "",

				// fbaAddress:{},

				// isShowSkuCheck: false,
				form: {
					"whNo": "", //this.$t('i18nn_5acbec83efb27445'),
					"packingNo": "", //this.$t('i18nn_3d3bc466f13154d4'),
					"packType": "", //this.$t('i18nn_643e08ba53f72f39'),
					"trType": "", //this.$t('i18nn_e1e2b21c7b4b951f'),
					"planArrivalDate": "", //this.$t('i18nn_9be246e60da2a0a2'),
					"pallteCount": "", //this.$t('i18nn_c8773d8d74202801'),
					"remark": "", //this.$t('i18nn_15b3627faddccb1d'),

					"plandtoList": [{
						"whNo": null, //this.$t('i18nn_a3b6f85b4a9530ff'),
						"addrType": null, //this.$t('i18nn_0bbce74881c893df'),
						"trAddrCode": null, //this.$t('i18nn_8758fd50c87d6c9c'),
						"trWay": null, //this.$t('i18nn_a29209bd396e4bdd'),
						"isChangeLabel": null, //是否换标
						// "attachments": [], //this.$t('i18nn_f5d43732e3f6cf4d')
						// pallteCount: 0,
						// remark: "",
						"planList": [
							// {
							// 	"ctnMark": null, //this.$t('i18nn_73fc38e67381ae73'),
							// 	"quantity": null, //this.$t('i18nn_f9db93b87e08763b'),
							// 	"trRecordId": null, //"入库明细ID",
							// 	"fbaNo": null, //"FBA编号",
							// 	"fbaPre": null, //"FBA PRE#"
							// },
						]
					}],
				},

				formRules: {
					// sendNo: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// recevieName: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
				},
				loading_load: false,
				selectOption: {
					whNo: [],
					biz_wh_tr_plan_way: [],
					biz_wh_tr_plan_addr_type: [],
					// wh_transfer_Inwh_type: [],
					wh_car_tms_type: [],
					wh_transfer_arrive_way: [],
					yes_no: []
				},

				dialogFormStatus: 0, //0-新增，1-修改

				isOnlyView: false,

			};
		},
		// watch: {
		// 	'$route.query': function(newVal, oldVal) {
		// 		console.log('$route.query', newVal);
		// 		// if(newVal){
		// 		// this.dialogFormVisible = true;
		// 		this.initData();
		// 		// }
		// 	}
		// },
		//创建时
		created() {
			// this.getPageData();
			this.initData();
		},
		//编译挂载前
		mounted() {

			// this.getDicData();
			//数据字典
			getDicData(['biz_wh_tr_plan_way', 'biz_wh_tr_plan_addr_type',
						'wh_transfer_arrive_way', 'wh_car_tms_type', 'yes_no'],
				(data)=>{
					this.selectOption.biz_wh_tr_plan_way = data['biz_wh_tr_plan_way'];
					this.selectOption.biz_wh_tr_plan_addr_type = data['biz_wh_tr_plan_addr_type'];
					this.selectOption.wh_transfer_arrive_way = data['wh_transfer_arrive_way'];
					this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
					this.selectOption.yes_no = data['yes_no'];
			});
			// this.getWhListData();
		},
		methods: {
			initData() {
				// this.$nextTick(() => {
				// 	this.$refs.HotTableTransport.clear();
				// });
				// this.isOnlyView = false;

				if (this.$route.query.editId) {
					this.editId = this.$route.query.editId;
					//不允许编辑
					// this.hasEdit = false;
					this.getPageBaseData(this.editId);
					// this.isOnlyView = true;
					// this.fileRelationId = this.editId;
				} else {
					// this.initTransportInventory();
				}

				// this.openUploadFile();
				// this.FileUploadOpenTime = new Date().getTime();
				// else {
				// 	this.hasEdit = true;
				// }
			},

			goBack() {
				// this.$router.push({
				// 	name: 'TransportOutWh'
				// });
				this.$router.go(-1);
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			
			trWayChange(v,index){
				let item = this.form.plandtoList[index];
				item.addrType = null;
				item.trAddrCode = null;
				item.trAddrInfo = "";
				this.$set(this.form.plandtoList, index, item);
				this.$forceUpdate();
			},

			//打开选项抽屉
			// openDrawer(index) {
			// 	if (!this.form.plandtoList[index]) {
			// 		this.$message.warning(this.$t('i18nn_af757a29a5046ebe'));
			// 		return;
			// 	}
			// 	if (!this.form.whNo) {
			// 		this.$message.warning(this.$t('FormMsg.Select_long_out_wh'));
			// 		return;
			// 	}
			// 	// console.log(this.form.plandtoList[index]);
			// 	// if (!this.form.trStockSour) {
			// 	// 	this.$message.warning(this.$t('FormMsg.Select_long_goods_source'));
			// 	// 	return;
			// 	// }
			// 	// this.form.trStockSour = "10"; //默认转运入库-源
			// 	this.drawer = true;
			// 	//查询对应仓库数据
			// 	this.TransportInWhDetOpenTime = new Date().getTime();
			// 	this.TransportInWhDetWhNo = this.form.whNo;
			// 	this.$forceUpdate();
			// },
			// changeTrStockSour(V) {
			// 	//查询对应仓库数据
			// 	// this.TransportInWhDetOpenTime = new Date().getTime();
			// 	// this.TransportInWhDetWhNo = this.form.whNo;
			// 	this.$forceUpdate();
			// },
			handleClick(tab, event) {
				console.log(tab, event);
			},
			//添加的选择的数据-转运的货物
			// addRec() {
			// 	this.$nextTick(() => {
			// 		let selData = this.$refs.TransportInWhDetPageList.getSelData();
			// 		console.log("selData", selData);
			// 		if (selData.length <= 0) {
			// 			this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
			// 			return;
			// 		}

			// 		let index = this.TransportOpenIndex;

			// 		let blockData = this.form.plandtoList[index];


			// 		selData.map(item => {
			// 			// let trCtnCount = parseFloat(item.stock ? item.stock : 0) - parseFloat(item.lockTotal ? item.lockTotal : 0);
			// 			let trCtnCount = null;
			// 			blockData.planList.push({
			// 				"id": null, //"数据ID",
			// 				"inWhCode": item.inWhCode,
			// 				"ctnMark": item.ctnMark, //this.$t('i18nn_73fc38e67381ae73'),
			// 				"quantity": trCtnCount, //this.$t('i18nn_f9db93b87e08763b'),
			// 				"trRecordId": item.id, //"入库明细ID",
			// 				"fbaNo": null, //"FBA编号",
			// 				"fbaPre": null, //"FBA PRE#"
			// 			});
			// 		});


			// 		this.$set(this.form.plandtoList, blockData, index);
			// 		this.$forceUpdate();

			// 		// this.form.plandtoList = this.form.plandtoList.concat(new_plandtoList);

			// 		this.drawer = false;
			// 	});
			// },

			//新增块数据
			AddBlockData() {
				if (this.form.plandtoList.length >= 10) {
					this.$message.warning("不能超过10个批次");
					return;
				}
				this.form.plandtoList.push({
					"whNo": null, //this.$t('i18nn_a3b6f85b4a9530ff'),
					"addrType": null, //this.$t('i18nn_0bbce74881c893df'),
					"trAddrCode": null, //this.$t('i18nn_8758fd50c87d6c9c'),
					"trWay": null, //this.$t('i18nn_a29209bd396e4bdd'),
					"isChangeLabel": null, //是否换标
					// "attachments": [], //this.$t('i18nn_f5d43732e3f6cf4d')
					// pallteCount: 0,
					// remark: "",
					"planList": [
						// {
						// 	"ctnMark": null, //this.$t('i18nn_73fc38e67381ae73'),
						// 	"quantity": null, //this.$t('i18nn_f9db93b87e08763b'),
						// 	"trRecordId": null, //"入库明细ID",
						// 	"fbaNo": null, //"FBA编号",
						// 	"fbaPre": null, //"FBA PRE#"
						// },
					]
				});
			},
			DelBlockData(event, index, item) {
				event.stopPropagation();
				if (item.id) {
					this.$confirm('确定删除该条吗？', this.$t('tips.tipsTitle'), {
							type: 'warning'
						})
						.then(() => {
							this.postData(this.$urlConfig.WhTransferPlanOutWhDel, {
								"ids": [item.id]
							}, () => {
								console.log('submitSuccess');
								this.$message.success(this.$t('i18nn_0c901139f9896f14'));
								this.form.plandtoList.splice(index, 1);
							});
						})
						.catch(() => {});

				} else {
					this.form.plandtoList.splice(index, 1);
				}

			},

			//新增
			addDet(index) {

				// let index = this.TransportOpenIndex;

				let blockData = this.form.plandtoList[index];


				// selData.map(item => {
				// let trCtnCount = parseFloat(item.stock ? item.stock : 0) - parseFloat(item.lockTotal ? item.lockTotal : 0);
				// let trCtnCount = null;
				blockData.planList.push({
					// "id": null, //"数据ID",
					// "inWhCode": item.inWhCode,
					"ctnMark": "", //this.$t('i18nn_73fc38e67381ae73'),
					"quantity": "", //this.$t('i18nn_f9db93b87e08763b'),
					"totalWeight": "",
					"totalVol": "",
					// "trRecordId": item.id, //"入库明细ID",
					"fbaNo": null, //"FBA编号",
					"fbaPre": null, //"FBA PRE#"
				});
				// });


				this.$set(this.form.plandtoList, blockData, index);
				this.$forceUpdate();

				// this.TransportOpenIndex = index;
				// this.openDrawer(index);

				// let blockData = this.form.plandtoList[index];
				// blockData.planList.splice(index2, 1);
				// this.$set(this.form.plandtoList, blockData, index);
				// this.$forceUpdate();
			},

			//新增
			// addDet(index) {
			// 	this.TransportOpenIndex = index;
			// 	this.openDrawer(index);

			// 	// let blockData = this.form.plandtoList[index];
			// 	// blockData.planList.splice(index2, 1);
			// 	// this.$set(this.form.plandtoList, blockData, index);
			// 	// this.$forceUpdate();
			// },
			// 删除
			// delDet(event, index, index2) {
			// 	event.stopPropagation();
			// 	let blockData = this.form.plandtoList[index];
			// 	// } else { //前端删除
			// 	blockData.planList.splice(index2, 1);
			// 	// }
			// 	this.$set(this.form.plandtoList, blockData, index);
			// 	this.$forceUpdate();
			// },
			
			delDet(event, index, index2) {
				event.stopPropagation();
				let blockData = this.form.plandtoList[index];
				if(blockData.planList && blockData.planList[index2] && blockData.planList[index2].id){//后端删除
					// this.delDetById(blockData.planList[index2].id);
					let id = blockData.planList[index2].id;
					this.$confirm(this.$t('i18nn_e19edf6996055dc5')+'？', this.$t('tips.tipsTitle'), {
							type: 'warning'
						})
						.then(() => {
							this.postData(this.$urlConfig.WhTransferPlanOutWhDetDel, {
								"id": id
							}, () => {
								this.$message.success(this.$t('i18nn_de017dafc266aa03'));
								//前端删除
								blockData.planList.splice(index2, 1);
							});
						})
						.catch(() => {});
				} else {//前端删除
					blockData.planList.splice(index2, 1);
				}
				this.$set(this.form.plandtoList, blockData, index);
				this.$forceUpdate();
			},
			
			//清空地址
			clearAddrCode(v, index) {
				let blockData = this.form.plandtoList[index];
				blockData.trAddrCode = "";
				blockData.trAddrInfo = "";
				this.$set(this.form.plandtoList, blockData, index);
				this.$forceUpdate();
			},
			//打开地址选择
			openSelAddr(index) {
				this.TransportOpenIndex = index;
				this.TransportAddressOpenTime = new Date().getTime();
			},
			addrSelSuccess(data) {
				let index = this.TransportOpenIndex;
				let blockData = this.form.plandtoList[index];
				blockData.trAddrCode = data.code;
				blockData.trAddrInfo = [data.address, data.city, data.state, data.country, data.zipCode].join(',');
				this.$set(this.form.plandtoList, blockData, index);
				this.$forceUpdate();
			},

			//添加附件
			// openAddFile(index) {
			// 	this.TransportOpenIndex = index;
			// 	this.FileUploadOpenTime = new Date().getTime();
			// },

			// //删除附件
			// delFile(event, index, index2) {
			// 	event.stopPropagation();
			// 	// this.form.qaProgress.attachments.splice(index, 1);
			// 	// this.$forceUpdate();

			// 	// let index = this.TransportOpenIndex;
			// 	let blockData = this.form.plandtoList[index];
			// 	blockData.attachments.splice(index2, 1);
			// 	this.$set(this.form.plandtoList, blockData, index);
			// 	this.$forceUpdate();

			// },

			// //附件上传成功回调
			// FileUploadSuccess(data) {
			// 	console.log('FileUploadSuccess', data);
			// 	let fileList = data.map(item => {
			// 		return {
			// 			fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
			// 			url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
			// 			type: "90"
			// 		}
			// 	});
			// 	// // this.form.qaProgress.attachments = fileList;
			// 	// this.form.qaProgress.attachments = this.form.qaProgress.attachments.concat(fileList);
			// 	// this.$forceUpdate();

			// 	let index = this.TransportOpenIndex;
			// 	let blockData = this.form.plandtoList[index];
			// 	blockData.attachments = blockData.attachments.concat(fileList);
			// 	// blockData.trAddrInfo = [data.address, data.city, data.state, data.country, data.zipCode].join(',');
			// 	this.$set(this.form.plandtoList, blockData, index);
			// 	this.$forceUpdate();

			// },

			// parsingSuccess(data){
			// 	this.formBase.sendDto = data;
			// },
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//提交(先提交附件，回调之后提交新增或者编辑方法)
			submitFormAction() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						// this.$nextTick(() => {
						// 	this.$refs.whFileUploadInner.submitExcelFormAction();
						// });
						this.submitData();
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
						return false;
					}
				});
			},

			//请求分页数据
			getPageBaseData(id) {
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferFastQuery + '/' + id, {})
					.then(({
						data
					}) => {
						console.log('分页，请求成功');
						console.log(data);
						this.loading = false;
						this.form = data.data;
						this.$nextTick(() => {
							this.$refs.whNoSelect.init(this.form.whNo);
						})

					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},

			submitData() {
				console.log('form', this.form);
				// let formData = this.form.plandtoList;
				// if (fbaAddrCode) {
				// 	formData.fbaAddrCode = fbaAddrCode;
				// }
				// console.log('formData', formData);
				if (this.editId) {
					let formData = this.form;
					// formData.trType = '10';
					formData.plandtoList.forEach(item => {
						item.whNo = formData.whNo;
					})
					console.log('formData', formData);
					this.postData(this.$urlConfig.WhTransferFastEdit, formData, () => {
						console.log('submitSuccess');
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						this.$emit('submitSuccess');
						// this.goBack();
					});
				} else {

					let formData = this.form;
					// formData.trType = '10';
					formData.plandtoList.forEach(item => {
						item.whNo = formData.whNo;
					})
					console.log('formData', formData);
					this.postData(this.$urlConfig.WhTransferFastAdd, formData, () => {
						console.log('submitSuccess');
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						this.$emit('submitSuccess');
						// this.goBack();
					});
				}
			},


			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					this.loading = false;
					if (200 == data.code) {

						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			//请求
			// getWhListData() {
			// 	this.$http
			// 		.put(this.$urlConfig.WhCusBindWhList, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.rows) {
			// 				// this.list = data.rows;
			// 				let list = data.rows;
			// 				this.selectOption.whNo = list.map(item => {
			// 					return {
			// 						code: item.whNo,
			// 						codeText: `${item.whName}(${item.address}.${item.city},${item.state} ${item.postalCode})`,
			// 						codeTextEn: `${item.whNameEn}(${item.address}.${item.city},${item.state} ${item.postalCode})`
			// 					}
			// 				});
			// 				// this.$emit('loadSuccess',this.list);
			// 				// this.$emit('loadAllSuccess',list);
			// 				// this.selectOption.wh_vol_unit = data.data["wh_vol_unit"];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.errorData');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			// console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.requestErrorData'));
			// 		});
			// },

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['biz_wh_tr_plan_way', 'biz_wh_tr_plan_addr_type',
			// 			'wh_transfer_arrive_way', 'wh_car_tms_type', 'yes_no'
			// 		])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.biz_wh_tr_plan_way = data.data['biz_wh_tr_plan_way'];
			// 				this.selectOption.biz_wh_tr_plan_addr_type = data.data['biz_wh_tr_plan_addr_type'];
			// 				this.selectOption.wh_transfer_arrive_way = data.data['wh_transfer_arrive_way'];
			// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				this.selectOption.yes_no = data.data['yes_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.queryErrorFailed');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.queryRequestFailed'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.hover-highlight-red {
		&:hover {
			border-color: red;
		}
	}
</style>